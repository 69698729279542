import React from 'react';
import './App.css';
import PeriodicElementFamily from './PeriodicElementFamily';
import DnsList from "./DnsList";

function App () {
    return (
        <div className="App">
            <header className="App-header">
                <div className={'Mendeleiev'}>
                    <PeriodicElementFamily color={'#FB75E4'}
                                           size={150}
                                           name={"Kseniya"}
                                           element={"Ksu"}
                                           number={"1"}
                                           weight={"1.985"}/>
                    <PeriodicElementFamily color={'#61DAFB'}
                                           size={150}
                                           name={"Thomas"}
                                           element={"To"}
                                           number={"2"}
                                           weight={"19.84"}/>
                    <PeriodicElementFamily color={'#FB536C'}
                                           size={150}
                                           name={"Noah"}
                                           element={"No"}
                                           number={"3"}
                                           weight={"20.16"}/>
                    <PeriodicElementFamily color={'#FBD943'}
                                           size={150}
                                           name={"Thaïs"}
                                           element={"Ta"}
                                           number={"4"}
                                           weight={"20.19"}/>
                </div>
                <DnsList/>
            </header>
        </div>
    );
}

export default App;
