import React from 'react';
import "./PeriodicElementFamily.css"

export default class PeriodicElementFamilyFamily extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            fontRatio: 1,
            positionRatio: 1
        };
        this.wrapperRef = React.createRef();
        // To use the 'this' keyword, we need to bind it to our function
        // this.onChange = this.onChange.bind(this);
    }

    updateDimensions () {
        console.log(this.wrapperRef.current.clientWidth)
        this.setState({
            fontRatio: 198 / this.wrapperRef.current.clientWidth,
            positionRatio: 1 - (Math.abs(198 / this.wrapperRef.current.clientWidth - 1) / 10)
        });
    }

    componentDidMount () {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render () {
        return (
            <div className="PeriodicElementFamily"
                 style={{
                     width: this.props.size + 'px',
                     height: this.props.size + 'px'
                 }}>
                <div className="PeriodicElementFamily-Wrapper"
                     style={{
                         width: this.props.size + 'px',
                         height: this.props.size + 'px',
                         borderColor: this.props.color
                     }}
                     ref={this.wrapperRef}>
                    <p className="PeriodicElementFamily-Number"
                       style={{
                           fontSize: (25 / this.state.fontRatio) + 'px',
                           top: (1 / this.state.positionRatio) + '%',
                           left: (2.5 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.number}</p>
                    <p className="PeriodicElementFamily-Element"
                       style={{
                           fontSize: (100 / this.state.fontRatio) + 'px',
                           top: (14 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.element}</p>
                    <p className="PeriodicElementFamily-Name"
                       style={{
                           fontSize: (22 / this.state.fontRatio) + 'px',
                           bottom: (11 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.name}</p>
                    <p className="PeriodicElementFamily-Weight"
                       style={{
                           fontSize: (15 / this.state.fontRatio) + 'px',
                           bottom: (2.5 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.weight}</p>
                </div>
            </div>
        )
    }
}