import React from 'react';
import "./PeriodicElementSimple.css"

export default class PeriodicElementSimple extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            fontRatio: 1,
            positionRatio: 1
        };

        this.wrapperRef = React.createRef();
    }

    updateDimensions () {
        console.log(this.wrapperRef.current.clientWidth)
        this.setState({
            fontRatio: 198 / this.wrapperRef.current.clientWidth,
            positionRatio: 1 - (Math.abs(198 / this.wrapperRef.current.clientWidth - 1) / 10)
        });
    }

    componentDidMount () {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render () {
        return (
            <span className="PeriodicElementSimple"
                  style={{
                      width: this.props.size + 'px',
                      height: this.props.size + 'px'
                  }}>
                <div className="PeriodicElementSimple-Wrapper"
                     style={{
                         width: this.props.size + 'px',
                         height: this.props.size + 'px',
                         borderColor: this.props.color
                     }}
                     ref={this.wrapperRef}>
                    <p className="PeriodicElementSimple-Number"
                       style={{
                           fontSize: (45 / this.state.fontRatio) + 'px',
                           top: (1 / this.state.positionRatio) + '%',
                           left: (4 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.number}</p>
                    <p className="PeriodicElementSimple-Element"
                       style={{
                           fontSize: (102 / this.state.fontRatio) + 'px',
                           top: (15 / this.state.positionRatio) + '%',
                           color: this.props.color
                       }}>{this.props.element}</p>
                </div>
            </span>
        )
    }
}