import React from 'react';
import "./DnsList.css"
import PeriodicElementSimple from "./PeriodicElementSimple"

export default class DnsList extends React.Component {

    render () {
        return (
            <div className="DnsList">
                <ul>
                    <li><a href={'http://cloud.ksuto.fr'}><PeriodicElementSimple size={50}
                                                  color={'#0082C9'}
                                                  element={"Ne"}
                                                  number={"1"}/>xtCloud</a>
                    </li>
                    <li><a href={'http://home.ksuto.fr'}><PeriodicElementSimple size={50}
                                                     color={'#96C927'}
                                               element={"Ho"}
                                               number={"2"}/>me assistant</a>
                    </li>
                    <li><a href={'http://plex.ksuto.fr'}><PeriodicElementSimple size={50}
                                                     color={'#E5A00D'}
                                               element={"Pl"}
                                               number={"3"}/>ex</a>
                    </li>
                    <li><a href={'http://bitwarden.ksuto.fr'}><PeriodicElementSimple size={50}
                                                                                     color={'#858585'}
                                                                                     element={"Bi"}
                                                                                     number={"4"}/>tWarden</a>
                    </li>
                    <li><a href={'https://ksuto.grafana.net/goto/6dWzLcfVk?orgId=1'}><PeriodicElementSimple size={50}
                                                  color={'#D40000'}
                                                  element={"Su"}
                                                  number={"5"}/>pervision</a>
                    </li>
                </ul>
            </div>
        )
    }
}